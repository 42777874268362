/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import MaintenanceThumbnail from 'theme/assets/images/maintenance.png';
import {FormattedMessage} from 'react-intl';
import BarmejLogo from 'theme/assets/images/barmej-white-logo.svg';
import KfasLogo from 'theme/assets/images/kfas-white-logo.svg';

const {PARTNER} = process.env;

const MaintenancePage = () => {
  return (
    <div>
      <div className="w-100 pv3 tc" style={{background: '#53a1eb'}}>
        <a href="https://barmej.com/?ref=maintenance">
          {PARTNER === 'KFAS' && <img src={KfasLogo} className="100" />}
          {PARTNER === 'BARMEJ' && <img src={BarmejLogo} className="128" />}
        </a>
      </div>
      <div className="w-80-ns w-90 center pv5">
        <div className="tc">
          <img src={MaintenanceThumbnail} alt="maintenance" />
          <h1 className="mb4">
            <FormattedMessage id="maintenance" />
          </h1>
          <h4 className="lh-copy mb3 normal">
            <FormattedMessage id="be_back_soon" />
          </h4>
          <h4 className="lh-copy normal">
            <FormattedMessage id="be_back_soon_2" />
          </h4>
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;
